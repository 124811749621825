/* HOME */
/* About home */
.about-container {
  display: grid;
  grid-template-columns: 40% 60%;
  grid-template-rows: auto auto;
  margin: 10% 18%;
  .item-1 {
    padding-top: 2%;
    .ab-h3-text {
      @include text($h1, 500);
      color: $txt-color-2;
    }
  }
}

/* Work home */
.work-container {
  background-color: $bg-color-2;
  padding-bottom: 10%;
  .filterBar {
    text-align: center;
    display: block;
    .filterList {
      overflow: hidden;
      display: inline-block;
      padding: 5% 0;
      list-style: none;
      margin-bottom: 0;
      .filterList-Item {
        display: inline-block;
        padding: 0.375rem 0.75rem;
        font-size: $p;
        &:hover {
          color: $txt-color-2;
          cursor: pointer;
        }
        &:active {
          color: $txt-color-2;
        }
      }
    }
  }

  .workGrid {
    display: flex;
    flex-wrap: wrap;
    position: relative;
    .workItem-container {
      justify-content: flex-start;
      align-items: center;
      width: 25%;
      position: relative;
      overflow: hidden;
      .workItem {
        @include link($p);
        border: none;
        background-color: transparent;
        outline: 0;
        &:focus {
          text-decoration: none;
          outline: 0;
          border: none;
          color: $txt-color-2;
          background-color: transparent;
          box-shadow: none;
        }
        &:hover img {
          transform: scale(1.4);
          transition: all 1s;
          z-index: 1;
        }
        img {
          width: 100%;
        }
        .text {
          position: absolute;
          width: 100%;
          height: 100%;
          opacity: 0;
          text-align: center;
          background: rgba(0, 0, 0, 0.8);
          padding-top: 40%;
          color: $txt-color;
          &:hover {
            opacity: 1;
            cursor: pointer;
            transition: all 800ms;
            z-index: 2;
          }
          h2 {
            font-size: 2rem;
          }
          p {
            font-size: $p;
          }
        }
      }
    }
  }
}

/* Contact home */
.contact-container {
  @include grid-2(10% 18%);
  .contact-item {
    text-align: left;
  }
  .item-form {
    padding-top: 5%;
  }
}

.h3-text {
  color: $txt-color-2;
  font-size: $p;
}
.p-text {
  font-size: $p;
  color: $txt-color;
  padding-top: 1%;
}

.line {
  @extend %line;
}

.contact-item {
  .h1-text {
    color: $txt-color;
    font-size: $h1;
    font-weight: 500;
  }
}

/* Form home */
.form {
  .form-row {
    @extend %flex-rev;
    margin: 3% 0 5% 0;
  }

  .form-row input[type="name"],
  .form-row input[type="email"],
  .form-row input[type="subject"],
  .form-row textarea {
    @include form(0.5px solid rgba(235, 235, 230, 0.87), 0.5% 0%, 100%);
    padding: 1%;
    border-radius: 3px;
  }
  .form-row input[type="name"]::placeholder,
  .form-row input[type="email"]::placeholder,
  .form-row input[type="subject"]::placeholder,
  .form-row textarea::placeholder {
    color: $bg-color-2;
    padding: 1%;
  }
  .form-row button {
    @include button(1% 8%);
    color: $txt-color;
    background-color: $txt-color-2;
    &:hover {
      cursor: pointer;
      background-color: $hov-color;
      color: $bg-color-2;
    }
  }
}
