//Sunsrace Page
.sunsrace-container {
  margin: 0% 18% 6% 18%;
  .sr-images {
    img {
      width: 100%;
    }
    margin-bottom: 1%;
  }
  .workbanner-container {
    @extend %iframe-responsive;
  }
}
