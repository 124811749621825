/* wip page */
.wip {
    margin: 10% 0;
    .year {
        font-size: $p;
        font-weight: 400;
        padding-top: 2%;
        text-align: end;
    }
    .date {
        font-size: $p;
        font-weight: 400;
        padding-top: 2%;
        text-align: end;
        color: $txt-color-2;
    }
    .title {
        font-size: $h1;
        font-weight: 600;
        padding-bottom: 2%;
    }
}
.video-container {
    @extend %iframe-responsive;
}

.video-description {
    padding-top: 2%;
    font-size: $p;
    font-weight: 400;
}